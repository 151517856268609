// See: https://v10.material.angular.io/guide/theming
@use 'sass:map';
@use "@angular/material" as mat;
@import "@angular/material/prebuilt-themes/indigo-pink.css";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the theme.
$celo-primary: mat.define-palette(mat.$blue-palette);
$celo-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$celo-theme-light: mat.define-light-theme(
  (
    color: (
      primary: $celo-primary,
      accent: $celo-accent,
    ),
  )
);


$celo-blue-palette: (
 50: #e4f3fe,
 100: #bddffe,
 200: #93ccfd,
 300: #69b8fc,
 400: #49a9fc,
 500: #2f9afb,
 600: #2c8bec,
 700: #2879d9,
 800: #2568c7,
 900: #1f4aa7,

 contrast: (
   50: rgba(black, 0.87),
   100: rgba(black, 0.87),
   200: rgba(black, 0.87),
   300: white,
   400: white
 )
);

$my-primary: mat.define-palette($celo-blue-palette, 400);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The "warn" palette is optional and defaults to red if not specified.
$my-warn: mat.define-palette(mat.$red-palette);

$my-theme: mat.define-light-theme((
 color: (
   primary: $my-primary,
   accent: $my-accent,
   warn: $my-warn,
 )
));

@include mat.slide-toggle-theme($my-theme);
@include mat.checkbox-theme($my-theme);
@include mat.form-field-theme($my-theme);
@include mat.icon-theme($my-theme);

// Include the theme styles for only specified components.
@include mat.legacy-checkbox-theme($my-theme);
@include mat.legacy-form-field-theme($my-theme);

// See: https://v16.material.angular.io/guide/typography
$celo-typography: mat.define-typography-config(
  $headline-6: mat.define-typography-level(22px, normal, 500),
  $subtitle-2: mat.define-typography-level(16px, normal, 500),
);

// Override typography CSS classes (e.g., mat-h1, mat-display-1, mat-typography, etc.).
@include mat.typography-hierarchy($celo-typography);
