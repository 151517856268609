/* You can add global styles to this file, and also import other style files */

//$material-design-icons-font-path: '~material-design-icons-iconfont/dist/fonts/';
//@import '~material-design-icons-iconfont/src/material-design-icons';
@use "@angular/material" as mat;

@import "variables";
@import "shared";
@import "@ctrl/ngx-emoji-mart/picker";

@include mat.slide-toggle-density(-5);
@include mat.checkbox-density(-1);

* {
  margin: 0;
}

html {
  --banner-height: 48px;
  --nav-height: 66px;
  --content-height: calc(100vh - var(--nav-height));
}

.red {
  background-color: red;
}

.grey {
  background-color: grey;
}

.green {
  background-color: green;
}

.blue {
  background-color: blue;
}

.yellow {
  background-color: yellow;
}

.white {
  background-color: white;
}

.black {
  background-color: black;
}

html,
body {
  height: 100%;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  font-weight: normal !important;
}

body {
  // dont use this. This will affect the unsupported screen, which needs to be responsive
  // min-width: 1100px;
  overflow: auto;
}

.logo {
  width: 160px;
}

.img-container {
  max-height: 300px;
  max-width: 400px;
  width: 95vw;
}

.backdropClass {
  background-color: $celoBlue;
  opacity: 1 !important;
}

.panelClass {
  mat-mdc-dialog-container {
    max-height: 95vh;
    overflow-y: auto;
    padding: 0;
  }
}

.mat-mdc-dialog-container {
  border-radius: 20px !important;

  .mdc-dialog__surface {
    padding: 20px;
    border-radius: 20px !important;
    overflow-x: hidden;
  }

  .mdc-dialog__container {
    box-shadow:
      11px 15px -7px #0003,
      0 24px 38px 3px #00000024,
      0 9px 46px 8px #0000001f;
  }

  overflow: auto !important;
  overflow-x: hidden !important;
}

.celo-taller {
  height: 98vh;

  mat-mdc-dialog-content {
    height: calc(98vh - 81px);
    max-height: calc(98vh - 81px);
    margin-bottom: -24px;
  }
}

.get-app-box {
  background-color: rgba(0, 0, 0, 0.02);
  color: rgb(130, 130, 130);
  border-radius: 4px;
  text-align: center;
  padding: 5px;
  margin-bottom: 3px;
}

.get-app-box:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.lightbox-panel {
  height: 100vh;
  width: 100vw !important;
  max-width: 100vw !important;
  background-color: rgba($color: black, $alpha: 0.3);

  .mat-mdc-dialog-container {
    background: inherit;
    position: relative;
    // max-height: 95vh;
    overflow-y: auto;
    border-radius: 0 !important;

    padding: 0;

    mat-mdc-dialog-content {
      height: 100vh;
      max-height: 100vh;
      width: 100vw;
      max-width: 100vw;
      margin: 0;
      padding: 0;
    }

    .mat-mdc-dialog-surface {
      border-radius: 0 !important;
      background-color: rgba($color: black, $alpha: 0.3);
      padding: 0;
    }
  }

  .button-bar {
    margin-top: 20px;

    .left {
      padding-left: 50px;
    }

    .right {
      float: right;
      padding-right: 50px;
    }
  }
}

.dialog-panel {
  mat-mdc-dialog-container {
    border-radius: 10px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 500px !important;
  }
}

.add-workspace-dialog {
  mat-mdc-dialog-container {
    border-radius: 10px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 860px !important;
  }
}

.link-action-dialog {
  mat-mdc-dialog-container {
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    min-width: 350px !important;
    padding-top: 40px;
    padding-bottom: 30px;
    text-align: center;
    position: relative;
  }

  .logo {
    width: 160px;
  }

  .cover {
    width: 300px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: $celoDarkGrey;
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button {
      min-width: 160px !important;
      // margin-left: auto;
      // margin-right: auto;
    }
  }
}

.alert-panel {
  width: 600px;

  mat-mdc-dialog-container {
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 600px !important;
    position: relative;
  }

  .cover {
    width: 300px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
    padding-left: 0px;
  }

  .subtitle {
    font-size: 14px;
    color: $celoDarkGrey;
  }
}

.new-chat {
  // width: 800px;
  padding: 5px;

  mat-mdc-dialog-container {
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 800px !important;
    position: relative;
  }

  .cover {
    width: 300px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
    padding-left: 0px;
  }

  .subtitle {
    font-size: 14px;
    color: $celoDarkGrey;
  }
}

.directory-picker {
  max-width: 95vw !important;
  width: 100%;

  mat-mdc-dialog-container {
    width: 100%;
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    position: relative;
  }
}

.error-panel,
.alert-panel {
  max-width: 500px !important;
}

.profile-dialog {
  padding: 0px !important;

  mat-mdc-dialog-container {
    padding: 0px !important;
    border-radius: 20px;
    max-height: 95vh;
    overflow-y: auto;
    max-width: 450px !important;
    width: 450px !important;
    position: relative;
  }

  .logo {
    width: 160px;
  }

  .cover {
    width: 300px;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    padding: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: $celoDarkGrey;
  }
}

[data-letters]:before {
  content: attr(data-letters);
  position: absolute;
  left: 0.8em;
  top: 0.8em;
  display: inline-block;
  font-size: 0.85em;
  width: 1.8em;
  height: 1.8em;
  line-height: 1.8em !important;
  text-align: center;
  border-radius: 50%;
  background: plum;
  vertical-align: middle;
  margin-right: 1em;
  color: white;
}

.no-outline,
.no-outline:focus,
input:focus,
textarea:focus,
a[mat-fab]:focus,
a[mat-mini-fab]:focus,
a[mat-raised-button]:focus,
button,
button:focus {
  outline: none !important;
}

.doctor-cartoon {
  width: 100%;
  max-height: 400px;
  padding-bottom: 20px;
}

@media screen and (max-width: 1023px) {
  .doctor-cartoon {
    display: none;
  }

  .doctor-cartoon {
    display: none;
  }
}

// ::-webkit-scrollbar {
//   width: 10px;
// }

// __<<ngThemingMigrationEscapedComment1>>__
// ::-webkit-scrollbar-track {
//   background: transparent;
// }

// __<<ngThemingMigrationEscapedComment2>>__

// ::-webkit-scrollbar-thumb {
//   background: lightgray;
// }

// __<<ngThemingMigrationEscapedComment3>>__
// ::-webkit-scrollbar-thumb:hover {
//   background: darkgray;
// }

.spinner-container {
  display: flex;
  height: 100%;
  width: 100%;

  mat-spinner,
  mat-progress-spinner,
  lottie-animation-view,
  .spinner {
    display: block;
    margin: auto;
    opacity: 0.8;
  }
}

.mat-mdc-progress-bar {
  height: 2px !important;
}

.celo-centered {
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.celo-text-centred {
  text-align: center;
}

.celo-centered-horizontal {
  display: flex;
  width: 100%;
  justify-content: center;
}

.celo-elipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.celo-elipsis-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.celo-break-word {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;
  /* Adds a hyphen where the word breaks, if supported */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.celo-less-bottom-margin {
  margin-bottom: -1.25em;
}

.celo-darkwhite-background {
  background-color: #f6f6f6;
}

.test {
  height: 50px;
  width: 100%;
}

.p_50 {
  padding: 50pc;
}

.font-11 {
  font-size: 11px;
}

.font-12 {
  font-size: 12px;
}

.font-14 {
  font-size: 14px;
}

.font-16 {
  font-size: 16px;
}

.font-17 {
  font-size: 17px;
}

.font-18 {
  font-size: 18px;
}

.font-20 {
  font-size: 20px;
}

.font-21 {
  font-size: 21px;
}

.icon-s {
  font-size: 14px;
}

.icon-m {
  font-size: 20px;
}

.icon-l {
  font-size: 30px;
}

.icon-xxl {
  font-size: 250px;
}

.icon-xxxl {
  font-size: 300px;
}

.svgIcon {
  height: 26px;
  width: 26px;
  margin-right: 20px;
}

.font-500 {
  font-weight: 500;
}

.font-grey {
  color: rgb(160, 160, 160);
}

.fill-hw {
  height: 100%;
  width: 100%;
}

.celo-radio-button {
  .mat-mdc-radio-button.mat-accent.mat-radio-checked
    .mat-mdc-radio-outer-circle {
    border-color: $celoSecondary;
  }

  .mat-mdc-radio-button.mat-accent .mat-mdc-radio-inner-circle {
    color: $celoSecondary;
    background-color: $celoSecondary;
  }

  .mat-mdc-radio-button.mat-accent
    .mat-mdc-radio-ripple
    .mat-mdc-ripple-element {
    background-color: $celoSecondary;
  }
}

.celo-dialog-title {
  // Roboto Medium, 22px, Black #000000
  font-size: 22px;
  color: black;
}

.celo-dialog-content {
  // Roboto, 14px, Grey #000000 50%
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.mute-dialog-box {
  .mute-dialog {
    margin: 50px;
    max-width: 400px;
    min-width: 300px;

    .dialog-title {
      margin-bottom: 10px;
    }

    .dialog-subtitle {
      margin-bottom: 20px;
    }

    .dialog-content {
      margin-top: 20px;
      min-width: 400px;
    }

    .title {
      // Roboto Medium, 22px, Black #000000
      font-size: 22px;
      color: black;
    }

    .content {
      // Roboto, 14px, Grey #000000 50%
      font-size: 14px;
      color: rgba(0, 0, 0, 0.5);
    }
  }

  mat-mdc-dialog-container {
    padding: 50px;
    max-height: 95vh;
    overflow-y: auto;

    .row {
      margin-top: 10px;

      .radio-button {
        font-size: 13px;
        font-weight: 400;
        padding-top: 5px;
        padding-bottom: 5px;
      }

      .mat-mdc-radio-button.mat-accent.mat-radio-checked
        .mat-mdc-radio-outer-circle {
        border-color: $celoSecondary;
      }

      .mat-mdc-radio-button.mat-accent .mat--mdc-radio-inner-circle {
        color: $celoSecondary;
        background-color: $celoSecondary;
      }

      .mat-mdc-radio-button.mat-accent
        .mat-mdc-radio-ripple
        .mat-mdc-ripple-element {
        background-color: $celoSecondary;
      }
    }
  }

  mat-mdc-dialog-actions {
    margin-top: 25px;

    button {
      width: 100px;
    }

    .yes-button {
      color: white;
      background-color: $celoSecondary;
    }

    .no-button {
      color: $celoSecondary;
      background-color: white;
    }
  }
}

.celo-primary-input {
  min-height: 40px;
  min-width: 350px;
  width: 100%;
  border-radius: 8px;
  padding: 10px;
  border-style: none;
  box-shadow: inset 0px 0px 2px rgba(0, 0, 0, 0.4);
}

.celo-primary-input::placeholder {
  color: rgba(0, 0, 0, 0.3);
}

.celo-primary-input:hover,
.celo-primary-input:focus {
  box-shadow: inset 0px 0px 2px rgba(62, 145, 255, 0.5);
  background: rgba(0, 0, 0, 0.01);
}

.celo-button-sm {
  text-align: center;
  min-width: 40px !important;
  width: 80px;
  height: 25px !important;
  min-height: 25px !important;
  display: flex !important;
  justify-content: center !important;
  letter-spacing: 0.5px;

  span {
    font-size: 12px;
    align-self: center !important;
  }
}

.celo-primary-button {
  background-color: $celoSecondary !important;
  color: white !important;
  min-width: 100px !important;
  border-radius: 7px !important;
  box-shadow: none;
  transition: box-shadow 0.5s ease-in-out;
  outline: none;
  border: none;
  text-align: center;
  letter-spacing: 0.5px;
  height: 40px;
  // font-size: 12px;
  font-size: 16px;
  white-space: nowrap;

  mat-icon {
    margin-left: 5px;
    margin-right: 5px;
  }

  .mat-mdc-progress-spinner circle {
    stroke: #ffffff !important;
  }
}

.celo-primary-button:hover {
  box-shadow: 2px 2px 3px rgba(145, 145, 145, 0.5);
}

.celo-primary-button:disabled,
.celo-secondary-button:disabled {
  opacity: 0.4;
}

.celo-secondary-button {
  color: $celoSecondary !important;
  background-color: $celoSecondaryLight !important;
  min-width: 100px !important;
  border-radius: 7px !important;
  box-shadow: none;
  outline: none;
  border: none;
  text-align: center;
  // font-size: 12px;
  font-size: 16px;
  letter-spacing: 0.5px;
  height: 40px;
  white-space: nowrap;
}

.celo-secondary-button:hover {
  box-shadow: 2px 2px 3px rgba(145, 145, 145, 0.2);
}

.celo-secondary-button-o {
  color: $celoSecondary !important;
  background-color: white !important;
  min-width: 100px !important;
  border-radius: 4px !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: $celoSecondary !important;
  transition: background-color 0.2s ease-in-out;
}

.celo-secondary-button-o:hover {
  background-color: rgb(244, 244, 244) !important;
}

.celo-tertiary-button {
  color: $celoBlue !important;
  min-width: 60px !important;
  border-radius: 7px !important;
  outline: none;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400 !important;
  letter-spacing: 0.5px;
  white-space: nowrap;

  span {
    font-size: 14px;
    // font-weight: 400!important;
    padding-left: 5px;
    padding-right: 5px;
  }

  i {
    color: $celoBlue !important;
  }
}

.bold {
  font-weight: 500;
}

.celo-danger-button {
  color: $celoBlue !important;
  min-width: 60px !important;
  outline: none;
  border: none;
  text-align: center;
  font-size: 14px;
  font-weight: 400 !important;
  color: $celoRed !important;
  min-width: 100px !important;

  span {
    font-size: 14px;
    font-weight: 400 !important;
    padding-left: 5px;
    padding-right: 5px;
  }

  i {
    color: $celoBlue !important;
  }
}

.celo-field-title {
  color: $celoDarkGrey;
  font-size: 12px;
}

.celo-grey {
  color: $celoDarkGrey;
}

.celo-heading {
  font-size: 22px;
}

.celo-text-11 {
  font-size: 11px;
}

.celo-text-12 {
  font-size: 12px;
}

.celo-text-14 {
  font-size: 14px;
}

.celo-text {
  font-size: 16px;
}

.celo-text-18 {
  font-size: 18px;
}

.celo-text-20 {
  font-size: 20px;
}

.celo-text-22 {
  font-size: 22px;
}

.celo-text-30 {
  font-size: 30px;
}

.celo-text-32 {
  font-size: 32px;
}

.celo-primary-button-blue {
  color: $celoBlue !important;
  min-width: 60px !important;
}

.celo-button {
  background-color: transparent !important;
  width: 100%;
  display: flex;
  height: 50px;
  padding-left: 8px;
  cursor: pointer;
  position: relative;

  .icon {
    color: $celoBlue !important;
    margin-right: 10px;
    align-self: center;
  }

  .text {
    color: black;
    align-self: center;
  }

  .posticon {
    position: absolute;
    right: 10px;
    align-self: center;
    color: rgba(0, 0, 0, 0.4) !important;
    // padding-left: 20px;
  }
}

// .celo-danger-button{
//   background-color: white!important;
//   color: $celoRed!important;
//   min-width: 100px!important;
// }
.celo-red-text {
  color: $celoRed !important;
}

// .celo-danger-button:disabled{
//   opacity: 0.4;
// }

.celo-light-button {
  color: rgb(230, 230, 230) !important;
  background-color: rgb(255, 255, 255) !important;
}

.celo-simple-button {
  color: $celoBlue !important;
  background-color: rgba(0, 0, 0, 0.03) !important;
  min-width: 100px !important;
}

.celo-simple-button:disabled {
  color: grey !important;
  opacity: 0.5;
}

.celo-secondary-icon-button-o {
  color: $celoSecondary !important;
  background-color: white !important;
  border-style: solid !important;
  border-width: 1px !important;
  border-color: $celoSecondary !important;
  transition: background-color 0.2s ease-in-out;
}

.celo-secondary-icon-button-o:hover {
  background-color: rgb(244, 244, 244) !important;
}

a {
  &:has(.celo-link) {
    text-decoration: none;
  }
}

.celo-link {
  color: $celoBlue !important;
  cursor: pointer !important;
  text-decoration: none;

  &.basic {
    padding: 0;
    line-height: inherit !important;
  }
}

.celo-link:hover {
  color: $celoBlue !important;
  text-decoration: underline !important;
}

.celo-link.disabled {
  color: $celoDarkGrey !important;
  pointer-events: none !important;
  cursor: default !important;
}

.celo-link-danger {
  color: $celoRed !important;
  cursor: pointer !important;
  text-decoration: none;
}

.celo-link-danger:hover {
  color: $celoRed !important;
  text-decoration: underline !important;
}

.celo-link-danger.disabled {
  color: $celoDarkGrey !important;
  pointer-events: none !important;
  cursor: default !important;
}

.celo-link-disabled {
  opacity: 0.4;
  pointer-events: none !important;
  cursor: default !important;
}

.thin-font {
  font-weight: 300 !important;
}

.normal-font {
  font-weight: normal !important;
}

.celo-no-select {
  user-select: none;
}

.celo-hoverable {
  background-color: rgba(0, 0, 0, 0) !important;
}

.celo-hoverable:hover {
  background-color: rgba(255, 29, 94, 0.04) !important;
}

.celo-hovered {
  background-color: rgba(255, 29, 94, 0.04) !important;
}

.celo-hoverable-white {
  background-color: rgb(255, 255, 255) !important;
}

.celo-hoverable-white:hover {
  background-color: rgb(255, 249, 251) !important;
}

.celo-hoverable-native:hover {
  background-color: rgba(255, 29, 94, 0.04) !important;
}

.celo-hoverable-light {
  background-color: rgba(255, 255, 255, 0) !important;
}

.celo-hoverable-light:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.celo-hoverable-dark {
  background-color: rgba(0, 0, 0, 0) !important;
}

.celo-hoverable-dark:hover {
  background-color: rgba(0, 0, 0, 0.03) !important;
}

.celo-bold {
  font-weight: 500 !important;
}

.celo-bold-400 {
  font-weight: 400 !important;
}

.celo-bold-500 {
  font-weight: 500 !important;
}

.celo-disabled {
  opacity: 0.4;
}

.button-hover {
  background: rgba(0, 0, 0, 0.02) !important;
}

.button-hover:hover,
.button-hover:active {
  background: rgba(0, 0, 0, 0.04) !important;
}

.uppercase {
  text-transform: uppercase !important;
}

.lowercase {
  text-transform: lowercase !important;
}

.appear-s {
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.2s linear;
}

.appear-m {
  opacity: 0;
  transition:
    visibility 0s,
    opacity 0.5s linear;
}

.appear {
  opacity: 0;
  transition:
    visibility 0s,
    opacity 1s linear;
}

.appear-long {
  opacity: 0;
  transition:
    visibility 0s,
    opacity 1.5s linear;
}

.appear-vlong {
  opacity: 0;
  transition:
    visibility 0s,
    opacity 2.5s linear;
}

.appear-now {
  visibility: visible;
  opacity: 1;
}

.appear_0 {
  animation: appear 0.4s ease-out;
}

.appear {
  animation: appear 0.6s ease-out;
}

.appear_1 {
  animation: appear 1s ease-out;
}

.appear_1_5 {
  animation: appear 1.5s ease-out;
}

.appear_2 {
  animation: appear 2s ease-out;
}

.appear_3 {
  animation: appear 2s ease-out;
}

.appear_4 {
  animation: appear 2s ease-out;
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.grow_initial {
  transform: scale(0);
}

.grow_0 {
  animation: grow 0.3s ease-out;
}

.grow_1 {
  animation: grow 0.6s ease-out;
}

.grow_2 {
  animation: grow 1s ease-out;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

.appear_theme_1 {
  animation: appear_theme 1s ease-out;
}

@keyframes appear_theme {
  0% {
    background-color: rgba(0, 140, 255, 0.2);
    // background-color: rgba(255, 238, 0, 0.3);
    opacity: 0;
  }

  100% {
    background-color: transparent;
    opacity: 1;
  }
}

.open-slow {
  overflow: hidden;
  transition: height 0.2s ease-out;
  height: 0px;
}

.celo-open-horizontal {
  overflow: hidden;
  transition: width 0.2s ease-out;
  width: 0px;
}

.open-slow-slow {
  overflow: hidden;
  transition: height 1s ease-out;
  height: 0px;
}

.snackbar {
  color: black;
}

.row {
  padding: 0px !important;
  margin: 0px !important;
}

.library {
  grid-area: celo-content;
  height: 100%;
  overflow-y: auto;
  padding: 0px !important;

  .library-container {
    height: 100%;
    position: relative;

    .library-tools {
      padding-left: 20px;
      padding-right: 20px;
    }

    .library-list {
      padding-left: 20px;
      padding-top: 5px;
      // box-shadow:inset 0 0 20px rgba(0, 0, 0, 0.1);
      // border-radius: 4px;

      // padding-right:20px;
    }

    .section-title {
      background: white;
      font-size: 13px;
      font-weight: 400;
    }

    .search-bar {
      padding-bottom: 5px;
      padding-top: 5px;
    }

    .toolbar {
      padding: 16px 0;
    }

    .group {
      margin-bottom: 30px;
    }

    .list-end {
      text-align: center;
      padding: 30px;
      opacity: 0.5;
    }

    .list {
      .item {
        border-radius: 4px;
        padding: 15px;
        min-height: 200px;

        .card {
          height: 100%;
          width: 100%;
          overflow: hidden;
          position: relative;
          object-fit: contain;
        }

        // .name-panel{
        //   background-color: rgba(255, 255, 255, 0.96);
        //   position: absolute;
        //   left: 0;
        //   right: 0;
        //   bottom: 0;
        //   div{
        //     padding: 5px;
        //     font-size: 14px;
        //     font-weight: 400;
        //     opacity: 0.8;
        //     width: 75%;
        //     text-overflow: ellipsis;
        //     overflow: hidden;
        //     white-space: nowrap;
        //   }
        //   .patient-id{
        //     font-size: 11px;
        //     border-radius: 6px;
        //     padding-left: 5px;
        //     padding-right: 5px;
        //     margin-left: 5px;
        //     // color: $celoSecondary;
        //     background: rgb(245, 245, 245);
        //     letter-spacing: 2px;
        //   }
        // }
      }
    }

    .grow-shadow {
      display: inline-block;
      vertical-align: middle;
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.7);
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      -moz-osx-font-smoothing: grayscale;
      -webkit-transition-property: box-shadow, transform;
      transition-property: box-shadow, transform;
    }

    .grow-shadow:hover,
    .grow-shadow:focus,
    .grow-shadow:active {
      box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.5);
    }
  }
}

.valid {
  position: relative;

  .drop-highlight {
    opacity: 1;

    div {
      div {
        transform: translateY(-30px);
      }

      i {
        transform: translateY(-20px);
      }
    }
  }
}

.drop-highlight {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.94);
  opacity: 0;
  z-index: 100;
  pointer-events: none;
  display: flex;

  div {
    text-align: center;
    align-self: center;
    width: 100%;
    letter-spacing: 3px;

    i {
      width: 100%;
      color: $celoBlue;
      transition: all 0.5s ease;
    }

    div {
      transition: all 0.7s ease;
    }
  }
}

.valid-do-move {
  position: relative;

  .do-move {
    opacity: 1;

    .child1 {
      transform: translateY(-40px);
    }

    .child2 {
      transform: translateY(-40px);
    }
  }
}

.do-move {
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  .child1 {
    transition: all 0.5s ease;
  }

  .child2 {
    transition: all 1.2s ease;
  }
}

.celo-page-title {
  font-size: 24px;
  font-weight: 500;
}

.celo-page {
  max-width: 1300px;
  width: 100%;
  height: 100%;
  padding-left: 3%;
  padding-right: 3%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 50px;
}

.celo-title {
  font-size: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.celo-subtitle {
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.tile-container {
  padding: 0;
  height: 100%;
  width: 100%;
  position: relative;
  cursor: pointer;
  overflow: hidden;

  pdf-viewer {
    overflow-x: hidden;
    overflow-y: hidden;
    display: block;
    max-width: 100%;
    opacity: 0.8;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .loading {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    display: flex;
    justify-content: center;

    mat-icon {
      font-size: 45px;
      height: 45px;
      width: 45px;
      align-self: center;
      justify-self: center;
      color: rgba(0, 0, 0, 0.09);
    }
  }

  .name-panel {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    .name-box {
      background-color: rgba(255, 255, 255, 0.95);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
      width: 100%;
      padding: 5px;
      padding-top: 2px;
      padding-bottom: 2px;
      padding-right: 30px;
      margin-top: 2px;
      display: flex;

      .by {
        align-self: center;
      }

      .name-field {
        align-self: center;
        padding: 5px;
        font-size: 12px;
        font-weight: 500;
        opacity: 0.8;
        width: 100%;
        padding-right: 30px;
      }
    }

    .patient-id {
      font-size: 11px;
      border-radius: 10px;
      padding: 4px;
      padding-left: 6px;
      padding-right: 6px;
      margin: 6px;
      margin-left: 3px;
      display: inline;
      float: left;
      background: rgba(255, 255, 255, 0.9);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.08);
      letter-spacing: 2px;
    }

    mat-icon {
      color: $celoSecondary;
      position: absolute;
      bottom: 2px;
      right: 2px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  img {
    object-fit: cover;
    width: inherit;
    height: inherit;
    overflow: hidden;
  }
}

.t-center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

.sentancify {
  text-transform: lowercase;
}

.sentancify::first-letter {
  text-transform: capitalize;
}

.mat-mdc-button-focus-overlay {
  background-color: transparent !important;
}

// Manipulate initial background color
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: transparent !important;
}

// Manipulate background color on hover and focus
.mat-mdc-form-field-focus-overlay {
  background-color: transparent !important;
}

.no-pointer-events {
  pointer-events: none;
}

.has-pointer-events {
  pointer-events: default;
}

.underline-on-hover:hover {
  text-decoration: underline;
}

.nopadding {
  padding: 0px !important;
}

.nomargin {
  margin: 0px !important;
}

.bottom-bar {
  margin-top: 40px;
  margin-bottom: 20px;
  width: 100%;

  button {
    width: 100%;
    padding-left: 0px;
    display: flex;
    justify-content: left;
    align-self: center;
  }

  .delete {
    color: $celoRed !important;
  }
}

.noscroll {
  overflow: hidden;
}

.fh {
  height: 100%;
}

.fw {
  width: 100%;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.celo-selectable {
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 4px;
}

.celo-selectable:hover {
  background: rgba(0, 0, 0, 0.03);

  div {
    pointer-events: none;
  }
}

.celo-selected {
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  padding: 4px;
}

.bubble {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.08);
  background: rgba(0, 0, 0, 0.016);
  border-radius: 4px;
  padding: 4px;
}

.bubble-line {
  border-radius: 8px;
  border-style: solid;
  border-color: rgb(240, 240, 240);
  border-width: 1px;
}

.flex {
  display: flex;

  .end {
    margin-left: auto;
  }

  .center {
    margin-left: auto;
    margin-right: auto;
  }
}

.align-self-center {
  align-self: center;
}

.table-shadow {
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
}

.celoSecondary {
  color: $celoSecondary;
}

.absolute {
  position: absolute;
}

.top {
  top: 0px;
}

.bottom {
  bottom: 0px;
}

.right {
  right: 0px;
}

.left {
  left: 0px;
}

.relative {
  position: relative;
}

.block {
  display: block;
}

.from-participant {
  .link {
    color: rgb(0, 0, 0) !important;
    opacity: 0.7;
  }

  .link:hover {
    color: rgb(0, 0, 0) !important;
    opacity: 0.85;
    font-weight: normal !important;
  }
}

.from-me {
  .link {
    color: white !important;
    opacity: 0.86;
  }

  .link:hover {
    opacity: 1;
    font-weight: normal !important;
  }
}

.link {
  text-decoration: underline;

  span {
    pointer-events: none;
  }
}

.link:hover {
  text-decoration: underline;
  font-weight: 500;
}

.absolute {
  position: absolute;
}

.left_0 {
  left: 0px;
}

.right_0 {
  right: 0px;
}

.top_0 {
  top: 0px;
}

.bottom_0 {
  bottom: 0px;
}

.marked {
  // background: $celoSecondary;
  // border-radius: 6px;
  // padding: 2px;
  // padding-left: 1px;
  // padding-right: 1px;
  font-weight: 500;
  color: black;
  // color: white;
}

.from-me {
  .marked {
    color: white;
  }
}

.case-bubble {
  margin-top: 4px;
  padding-bottom: 7px;
  padding-bottom: 0px;
  font-size: 13px;
  font-weight: normal;
  color: rgba(0, 0, 0, 0.6);
  display: flex;

  .case-title {
    color: $celoSecondary;
    font-size: 12px;
    opacity: 1;
    padding-left: 0px;

    padding-right: 4px;
    padding-top: 1px;
    padding-bottom: 1px;
    border-radius: 15px;
    align-self: center;
    font-weight: 500;
  }

  .case-content {
    padding-left: 5px;
    font-size: 12px;
    align-self: center;
    letter-spacing: 0.11px;
    color: #6d7278;
  }
}

// .mat-menu-panel{
//   mat-card ,.mat-card {
//     background: red!important;
//   }
// }

.slide_l {
  animation: 1s ease-out 0s 1 slideInFromLeft;
}

.slide_l_0_4 {
  animation: 0.4s ease-out 0s 1 slideInFromLeft;
}

.slide_r {
  animation: 1s ease-out 0s 1 slideInFromRight;
}

.slide_r_0_4 {
  animation: 0.4s ease-out 0s 1 slideInFromRight;
}

.slide_t {
  animation: 1s ease-out 0s 1 slideInFromTop;
}

@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes slideInFromTop {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.popup_anim_1 {
  animation: 0.3s ease-out 0s 1 slidePopup;
}

.popup_anim_2 {
  animation: 0.6s ease-out 0s 1 slidePopup;
}

.popup_anim_3 {
  animation: 0.9s ease-out 0s 1 slidePopup;
}

.popup_anim_4 {
  animation: 0.9s ease-out 0s 1 slidePopup;
}

.grow_anim_0 {
  animation: 0.1s ease-out 0s 1 grow_transition;
}

.grow_anim_1 {
  animation: 0.3s ease-out 0s 1 grow_transition;
}

.grow_anim_2 {
  animation: 0.6s ease-out 0s 1 grow_transition;
}

.grow_anim_3 {
  animation: 0.9s ease-out 0s 1 grow_transition;
}

@keyframes grow_transition {
  0% {
    height: 0px;
  }

  100% {
    height: auto;
  }
}

@keyframes slidePopup {
  0% {
    transform: translateY(10%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.appear_anim_0 {
  animation: 0.1s ease-out 0s 1 appear_transition;
}

.appear_anim_1 {
  animation: 0.3s ease-out 0s 1 appear_transition;
}

.appear_anim_2 {
  animation: 0.6s ease-out 0s 1 appear_transition;
}

.appear_anim_3 {
  animation: 0.9s ease-out 0s 1 appear_transition;
}

.appear_anim_4 {
  animation: 1.2s ease-out 0s 1 appear_transition;
}

.appear_anim_5 {
  animation: 1.4s ease-out 0s 1 appear_transition;
}

@keyframes appear_transition {
  0% {
    transform: translateY(20px);
    opacity: 0;
  }

  50% {
    transform: translateY(5px);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}

.mat-mdc-checkbox-content-fix {
  padding-top: 2px;
}

// .onboard{
//   display: flex;
//   height: 100%;
//   background: white;
//   .content{
//     height: 100%;
//     width: 50%;
//     display: flex;
//     justify-content: center;
//     .contents{
//       align-self: center;
//       display: flex;
//       justify-content: center;
//       flex-direction: column;
//       max-width: 400px;
//       text-align: center;
//       .logo{
//         padding: 10px;
//         width: 200px;
//         margin-left: auto;
//         margin-right: auto;
//       }
//       .title{
//         font-size: 18px;
//         font-weight: 500;
//         padding: 5px;
//       }
//       .subtitle{
//         font-size: 14px;
//         color: $celoDarkGrey;
//       }
//       .fields{
//         padding-top: 15px;
//         padding-bottom: 5px;
//       }
//       .buttons{
//         margin-top: 20px;
//         display: flex;
//         justify-content: center;
//         button{
//           margin: 10px;
//           width: 200px;
//         }
//       }
//     }
//   }
//   .cover{
//     height: 100%;
//     width: 50%;
//     display: flex;
//     justify-content: center;
//     .contents{
//       align-self: center;
//       width: 60%;
//       max-width: 400px;
//       img{
//         width: 100%;
//       }
//     }
//   }
// }
.celo-combained-mat-form-field .mat-mdc-form-field-infix {
  display: inherit;
}

#video-demo-container {
  width: 400px;
  margin: 40px auto;
}

#main-video {
  display: none;
  max-width: 400px;
}

#thumbnail-container {
  display: none;
}

#get-thumbnail {
  display: none;
}

#video-canvas {
  display: none;
}

#upload-button {
  width: 150px;
  display: block;
  margin: 20px auto;
}

.celo-chip {
  font-size: 14px;

  .mat-mdc-chip-list-wrapper input.mat-mdc-input-element,
  .mat-mdc-chip-list-wrapper .mat-mdc-standard-chip {
    margin: 2px !important;
  }

  .mat-mdc-chip.mat-mdc-standard-chip {
    background-color: #f0f0f0;
    color: rgba(0, 0, 0, 0.87);
  }

  .mat-mdc-chip {
    font-size: 13px;
    font-weight: normal;
  }
}

.mention {
  color: $celoBlue;
}

.from-participant {
  .mentioned-message {
    color: $celoBlue;
    font-weight: 500;
  }
}

.from-me {
  .mentioned-message {
    font-weight: 500;
  }
}

.standard-box {
  .mentioned-message {
    cursor: pointer;
    background-color: transparent;
    border-radius: 0px;
    transition: all 0.2s ease-in-out;
  }

  .mentioned-message:hover {
    cursor: pointer;
    border-radius: 6px;
    background-color: rgba(0, 0, 0, 0.04);
  }
}

// Used to reset the default styling on mat dialog panels
.mat-panel-reset {
  .mat-mdc-dialog-container {
    padding: 0;
  }
}

.user-picker {
  mat-mdc-dialog-container {
    border-radius: 10px;
  }
}

.export-confirmation {
  .mat-mdc-dialog-container {
    padding: 24px;
    max-width: 600px;
  }
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: none !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  letter-spacing: 0.5px !important;
  line-height: 28px !important;
  display: flex;
  align-items: center;
}

.mat-mdc-checkbox {
  --mdc-checkbox-selected-focus-state-layer-opacity: 0 !important;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0 !important;
  --mat-mdc-checkbox-persistent-ripple-color: none !important;
  --mdc-checkbox-selected-checkmark-color: #fff !important;
}

.mat-mdc-input-element {
  padding: 0;
}

.pl-3 {
  padding-left: 1rem !important;
}

.mb-0 {
  margin-bottom: 0;
}

.mt-20px {
  margin-top: 20px;
}

.mb-20px {
  margin-bottom: 20px;
}

.mb-40px {
  margin-bottom: 40px;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.flex {
  display: flex;
}

.flex-auto {
  flex: auto;
}

.flex-1 {
  flex: 1;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-self-end {
  justify-self: end;
}

.items-center {
  align-items: center;
}

.self-center {
  align-self: center;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.place-content-center {
  place-content: center;
}

.place-items-center {
  place-items: center;
}

.g-2 {
  gap: 4px;
}

.g-4 {
  gap: 8px;
}

.g-10px {
  gap: 10px;
}

.mat-mdc-menu-panel.profile-menu {
  overflow: auto;
  min-width: 350px;
  max-height: calc(99vh - var(--nav-height) - var(--banner-height));
  margin-top: -4px;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.w-500px {
  width: 500px;
}

.p-0 {
  padding: 0;
}

.mat-mdc-menu-panel.more-menu {
  width: fit-content;
}

.mat-mdc-snack-bar-container {
  &.voip-reminder-snackbar {
    --mdc-snackbar-container-color: #f0f0f0;
    --mdc-snackbar-supporting-text-color: #000;
  }
}

.pr-4 {
  padding-right: 4px;
}

.pl-16 {
  padding-left: 16px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.pb-8 {
  padding-bottom: 8px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-16 {
  padding-bottom: 16px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pb-32 {
  padding-bottom: 32px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.invisible {
  visibility: hidden;
}

.break-all {
  word-break: break-all;
}

.start-trial-cta-dialog,
.end-trial-cta-dialog,
.upgrade-prompt-dialog,
.roles-cta-dialog {
  .mat-mdc-dialog-container .mdc-dialog__surface {
    padding: 0;
  }

  margin: 8px;
}

mat-form-field#add-company-details-company-form-field {
  mat-label {
    margin-left: 16px !important;
  }

  .mat-mdc-text-field-wrapper.mdc-text-field.mdc-text-field--outlined {
    padding-left: 16px;
  }
}

mat-form-field#add-company-details-company-form-field {
  .mat-mdc-floating-label.mdc-floating-label--float-above {
    mat-label {
      margin-left: 0px !important;
    }
  }
}

.pointer-events-none {
  pointer-events: none;
}