$celoBlue: #49a9fc; // #doger-blue
$celoBlueTransparent: rgba(73, 169, 252, 0.2);
$celoBlueEnterprise: #1736bf; // #doger-blue
$celoBlueLight: #49a8fc94; // #doger-blue
$celoSecondary: #ff1d5e; // yellowish-Secondary
$celoSecondaryLighter: #ff3971; // yellowish-Secondary
$celoSecondaryLight: #ffe8ee; // yellowish-Secondary
$celoGreen: rgb(15, 185, 15); // yellowish-orange
$celoGreySelected: #ecf6ff; // pale-grey
$celoBrownGrey: #929292; // brown-grey
$celoBlueGrey: #acaeb1; // light-blue-grey
$celoBlueWhite: rgb(243, 249, 255); // light-blue-grey
$celoLightBlueGrey: #d1d1d6; // light-blue-grey
$celoGreyishBrown: #434343; // greyish-brown
$celoDarkGrey: #6d7278;
$celoLightGrey: #f0f0f0;
$celoDisabled: #8f8e94;
$celoRed: #e02020;
$celoRed10: #ff0000; // red-10
$celoBlack70: rgba(0, 0, 0, 0.7); // black-70
$celoBlack54: rgba(0, 0, 0, 0.54); //black-54
$celoBlack: #000;
$celoPlaceholder: #888;

$sidebarWidth: 360px;
