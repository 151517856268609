@import "variables";

.fill-space {
  flex: 1 1 auto;
}
//
//@mixin md-icon-size($size: 24px) {
//  font-size: $size;
//  height: $size;
//  width: $size;
//  line-height: $size;
//}

// scaffolding

.celo-grid {
  display: grid;
  grid-template-columns: $sidebarWidth 1fr;
  grid-template-areas: "left-side-nav celo-content"; // you can override this
  height: $content-height;
  grid-template-rows: 1fr;

  .celo-left-side-nav {
    border-right: 1px solid $celoLightBlueGrey; // sometimes over-ridden with #f6f6f6
    grid-area: left-side-nav;
    // content of side nav must decide its own padding, will often need full width.
  }

  .celo-pad-top {
    padding-top: $top-pad;
  }

  .celo-content {
    grid-area: celo-content;
  }

  @media (max-width: 1280px) {
    grid-template-columns: 28fr 72fr;
  }

  @media (max-width: 800px) {
    grid-template-columns: min-content 1fr;
  }
}

.celo-scrollable {
  max-height: 100%;
  overflow-y: auto;
}
.celo-500-host {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.celo-blue-color {
  color: $celoBlue;
}

.celo-orange-color {
  color: $celoSecondary;
}

.celo-border-bottom {
  // border-bottom: 1px solid #f6f6f6;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.celo-button-orange {
  padding: 9px 8px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  background-color: $celoSecondary;
  color: white;
  border: 1px solid white;
}
.celo-button-orange:disabled {
  opacity: 0.4;
}

.celo-button-orange-outline {
  padding: 9px 8px;
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;

  color: $celoSecondary;
  border: 1px solid $celoSecondary;
  background-color: white;
}
.celo-button-orange-outline:disabled {
  opacity: 0.4;
}

.celo-cursor-pointer {
  cursor: pointer;
}
